@keyframes bob{
    0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(0.5rem);
    }
    100%{
        transform: translateY(0);
    }
}

.second-choice-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);

    >.second-choice-content{
        display: flex;
        flex-direction: column;
        gap: 6rem;
        width: 80%;

        >.prev-choice{
            font-family: "inter", sans-serif;
            font-size: 2rem;
            font-weight: 700;
            color: white;
            width: 100%;
            text-align: left;
    
            >.prev{
                color: #7B2D90;
                font-family: "roboto", sans-serif;
                padding: 1.5rem 2rem;
                border-radius: 0.4375rem;
                background: white;
                box-sizing: border-box;
                margin-left: 1rem;
            }
        }
    
        >.choices-box{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 2.5rem;
            font-size: 4rem;
            font-weight: 700;
            text-align: center;

    
            >.choices-header{
                color: white;
                font-family: "Helvetica Neue";
                font-size: 3rem;
                font-style: normal;
                font-weight: 700;
                width: 100%;
                text-align: left;
            }

            >.choices-container{
                // width: 100%;
                width: 90%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 3.75rem;

                margin-bottom: 8.5rem;
            }
        }
    }

    >.back-arrow-container{
        display: flex;
        flex-direction: row;
        width: 95%;
        justify-content: flex-start;
        align-items: center;

        >.back-arrow{
            &:hover{
                cursor: pointer;
                filter: invert(100%);
            }
        }
    }
}

.option{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    flex: 0 0 33%;

    &:hover{
        cursor: pointer;
        animation: bob 0.5s;
    }

    >.option-label{
        // width: 100%;
        font-family: "roboto", sans-serif;
        font-size: 1.125rem;
        font-weight: 700;
        color: white;
        background: #7B2D90;
        padding: 0.5rem 1rem;
        border-radius: 0.625rem;
    }

    >.option-text{
        font-family: "roboto", sans-serif;
        font-size: 1.5rem;
        font-weight: 400;
        border-radius: 0.625rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 12rem;
        background: white;
        padding: 0rem 2.75rem;
    }
}