@import url('https://fonts.googleapis.com/css2?family=Helvetica+Neue:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: 'Helvetica Neue', sans-serif;
}

.slide4-container {
    position: relative;
    width: 100%;
    height: 100vh;
    background: url("../static/slide4.png") no-repeat center center;
    background-size: cover;
}

.slide4-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
        circle,
        #000000 0%,
        
        #000000 100%
    );
    opacity: 0.7;
}

.slide4-content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.back-arrow {
    position: absolute;
    top: 84px;
    left: 160px;
    width: 60px;
    height: 40px;
    cursor: pointer;
}

.enough-text {
    margin-bottom: 100px;
}

.header1 {
    font-size: 64px;
    margin: 0;
}

.highlight {
    text-decoration: underline;
}

.dots {
    position: absolute;
    bottom: 125px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    width: 100%;
}


.dot {
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    margin: 0 10px;
}

.dot.active {
    background-color: #BB6BD9;
}