@import url('https://fonts.googleapis.com/css2?family=Helvetica+Neue:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: 'Helvetica Neue', sans-serif;
}

.slide8-container {
    position: relative;
    width: 100%;
    height: 100vh;
    background: url('../static/slide7.png') no-repeat center center;
    background-size: cover;
}

.slide8-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
        circle,
        #000000 0%,
        
        #000000 100%
    );
    opacity: 0.9;
}

.slide8-content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
    text-align: left;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.slide8-back-arrow {
    position: absolute;
    top: 84px;
    left: 140px;
    width: 60px;
    height: 40px;
    cursor: pointer;
}

.slide8-subheader {
    font-size: 24px;
    font-weight: bold;
    z-index: 1; 
}

.slide8-header1-box {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.header1 {
    font-size: 64px;
    margin: 0;
}

.slide8-body-text {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    margin: 0;
    color: #636363;
}

.highlight {
    background-color: #7B2D90;
    padding: 0 5px;
    text-decoration: underline;
}


.dots {
    position: absolute;
    bottom: 125px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    width: 100%;
}

.dot {
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    margin: 0 10px;
}

.dot.active {
    background-color: #BB6BD9;
}

.begin-experience {
    position: absolute;
    bottom: 113px;
    right: 150px;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border: 2px solid white;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 18px;
}

.arrow-icon {
    margin-left: 10px;
    width: 16px;
    height: 16px;
}