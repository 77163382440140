@keyframes background-fade{
    0%{
        background-color: rgba(0, 0, 0, 0.5);
    }
    100%{
        background: rgba(204, 46, 46, 0.50);
    }
}

@keyframes text-fade{
    0%{
        color: black;
        background: rgba(255, 255, 255, 0.80);
    }
    100%{
        color: white;
        background: #C63636;
    }
}

.game-context-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    animation: background-fade 0.75s forwards;

}

.gameContext{
    height: 100vh;
    display: flex;
    // box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    >.context-text{
        font-family: "roboto", sans-serif;
        font-size: 1.5rem;
        font-weight: 400;
        padding: 1.8rem 3rem;
        border-radius: 0.625rem;
        z-index: 2;
        margin-bottom: 10rem;
        animation: 0.75s text-fade forwards;
        max-width: 50%
    }
}