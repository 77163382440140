@import url('https://fonts.googleapis.com/css2?family=Helvetica+Neue:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: 'Helvetica Neue', sans-serif;
}

.splash-container {
    position: relative;
    width: 100%;
    height: 100vh;
    background: url('../static/intro-splashScreen.png') no-repeat center center;
    background-size: cover;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
        circle,
        #9722B7 0%,
        #9722B7 70%,
        #240D2A 100%
    );
    opacity: 0.7;
}

.content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subheader {
    font-size: 24px;
    font-weight: bold;
    margin: 0 0 20px 0;
    z-index: 1;
}

.header-box {
    border: 8px solid white;
    background-color: #7B2D90;
    padding: 10px;
    display: flex;
    align-items: center;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
}

.header1 {
    font-size: 64px;
    font-weight: bold;
    margin: 0;
    padding-right: 20px;
}

.subheader-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.subheader-box .subheader {
    margin: 5px 0;
}