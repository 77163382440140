.spline-container{
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;    
    // transform: translateX(-0.5rem);
}

body{
    overflow: hidden;
}