.tip-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    gap: 2rem;

    >.tip-content-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 2rem;
        width: 75%;

        >.prev-label{
            font-family: "inter", sans-serif; 
            color: white;
            font-size: 2rem;
            font-weight: 700;
            width: 100%;
            text-align: left;
    
        }

        >.prev-box{
            // min-width: 20rem;
            // min-height: 12rem;
            border-radius: 0.625rem;
            background: rgba(255, 255, 255, 0.80);
            padding: 2rem 2.75rem;
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            // align-items: flex-start;
            align-items: center;
            gap: 1rem;

            >.prev-number-container{
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: flex-start;

                >.prev-number{
                    border-radius: 0.625rem;
                    background: #7B2D90;
                    color: white;
                    font-family: "inter", sans-serif;
                    font-size: 1.125rem;
                    font-weight: 700;
                    padding: 0.5rem 1rem;
                }
            }



            >.prev-text{
                font-family: "roboto", sans-serif;
                font-size: 1.5rem;
                font-weight: 400;
                gap: 0.75rem;
                text-align: left;
                // width: 75%;
                max-width: 18rem;
            }
            margin-bottom: 2rem;
        }

        >.tip-box{
            width: 100%;
            min-height: 12rem;
            background: white;
            border-radius: 1.25rem;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;

            >.tip-text{
                font-family: "roboto", sans-serif; 
                font-size: 1.5rem;
                font-weight: 400;
                text-align: left;
                padding: 3rem 3.5rem;
                flex: 0 0 50%;
                box-sizing: border-box;
                border-bottom: 2px solid #D9D9D9;
            }

            >.tip-bottom-container{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                flex: 0 0 50%;
                padding: 3rem 3.5rem;
                box-sizing: border-box;
                gap: 2rem;

                >.tip-suggest{
                    font-family: "roboto", sans-serif; 
                    font-size: 1.125rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            margin-bottom: 5rem;
        }
    }

    >.bottom-container{
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        >.bottom-button{
            &:hover{
                cursor: pointer;
                filter: invert(100%);
            }
        }
    }
}