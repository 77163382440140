@import url('https://fonts.googleapis.com/css2?family=Helvetica+Neue:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: 'Helvetica Neue', sans-serif;
}

.slide5-container {
    position: relative;
    width: 100%;
    height: 100vh;
    background: url("../static/slide4.png") no-repeat center center;
    background-size: cover;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
        circle,
        #000000 0%,
        
        #000000 100%
    );
    opacity: 0.7;
}

.content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.back-arrow {
    position: absolute;
    top: 84px;
    left: 160px;
    width: 60px;
    height: 40px;
    cursor: pointer;
}

.text-content {
    margin-top: 48px;
    margin-bottom: 0px;
}

.subheader {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
}

.info-box {
    background-color: rgba(123, 45, 144, 0.7);
    padding: 20px;
    border: 2px solid white;
    border-radius: 8px;
    margin-top: 48px;
}

.header1 {
    font-size: 64px;
    margin: 0;
    padding-bottom: 10px;
}

.holding-hand-icon {
    width: 110px;
    height: auto;
    margin-top: 46px;
    margin-bottom: 150px;
}



.dots {
    position: absolute;
    bottom: 125px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    width: 100%;
}

.dot {
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    margin: 0 10px;
}

.dot.active {
    background-color: #BB6BD9;
}