.outro-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);

    >.outro-box{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 4rem 6rem;
        box-sizing: border-box;
        width: 60%;
        height: 50%;
        gap: 2rem;
        border-radius: 1.25rem;
        background: rgba(255, 255, 255, 0.80);

        >.outro-header{
            font-family: "helvetica neue", sans-serif;
            font-size: 3rem;
            font-style: normal;
            font-weight: 700;
            width: 100%;
            text-align: left;
        }

        >.outro-text{
            font-family: "roboto", sans-serif;
            font-size: 1.25rem;
            width: 100%;
            text-align: left;
            font-weight: 400;
        }

        >.button-row{
            display: flex;
            flex-direction: row;
            width: 90%;
            justify-content: space-between;
            margin-top: 2rem;

            >.again-button{
                font-family: "roboto", sans-serif; 
                font-size: 1.5rem;
                padding: 0.75rem 1rem;
                background: white;
                font-weight: 700;
                color: #7B2D90;
                border-radius: 0.4375rem;

                &:hover{
                    cursor: pointer;
                    background: #7B2D90;
                    color: white;
                }
            }
        }
    }
}