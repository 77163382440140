.game-overlay{
    position: absolute;
    z-index: 999;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    >.game-overlay-box{
        background-color: white;
        font-family: "Spline Sans Mono", monospace;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 1.25rem;
        min-height: 30.7%;
        min-width: 52.9%;
        gap: 2rem;
    }
}

.hidden{
    display: none;
}