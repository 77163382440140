@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Khmer&family=Nanum+Gothic&family=Raleway:ital,wght@0,100..900;1,100..900&family=Spline+Sans+Mono:ital,wght@0,300..700;1,300..700&display=swap');

.overlay-container{
    position: absolute;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .overlay-box{
        background-color: white;
        font-family: "Spline Sans Mono", monospace;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 1.25rem;
        min-height: 30.7%;
        min-width: 52.9%;
        gap: 2rem;

        .overlay-title{
            font-size: 3rem;
            font-weight: 700;
        }

        .overlay-subtitle{
            font-size: 1.125rem;
        }
    }
}

.hide{
    display: none;
}