.game-speech-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    
    &:hover{
        cursor: pointer;
    }
}

.game-speech{
    // min-width: 52.9vw;
    height: 100vh;
    display: flex;
    // box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    >.text-container{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
        margin-bottom: 10.5rem;

        >.speaker-box{
            font-family: "inter", sans-serif;
            font-size: 1.5rem;
            font-weight: 700;
            padding: 0.63rem 1.5rem;
            color: white;
            background: #7B2D90;
            border-radius: 0.625rem;
            z-index: 2;
        }

        >.speech-row{
            display: flex;
            flex-direction: row;
            gap: 3rem;
                    
            >.speech-box{
                font-family: "roboto", sans-serif;
                font-size: 1.5rem;
                font-weight: 400;
                padding: 1.8rem 3rem;
                background: rgba(255, 255, 255, 0.80);
                border-radius: 0.625rem;
                z-index: 2;
            }
        }


    }

    // >.speech-bubble{
    //     background-color: white;
    //     font-family: "Spline Sans Mono", monospace;
    //     display: flex;
    //     flex-direction: column;
    //     padding: 3rem 6rem;
    //     border-radius: 1.25rem;
    //     width: 100%;
    //     max-width: 52.9vw;
    //     gap: 2rem;
    //     z-index: 2;
    //     box-sizing: border-box;
    //     margin-bottom: 17.5%;

    //     >.speaker{
    //         font-weight: 700;
    //     }
    // }

    >.game-speech-icon{
        z-index: 1;
        position: absolute;
        //Image export has padding so we need to offset by 5rem
        transform: translateX(-10rem);
        -khtml-user-select: none;
        -o-user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        user-select: none;
    }

    >.hidden{
        display: none;
    }

}

.hidden{
    display: none;
}