@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Khmer&family=Nanum+Gothic&family=Raleway:ital,wght@0,100..900;1,100..900&family=Spline+Sans+Mono:ital,wght@0,300..700;1,300..700&display=swap');

.button-container{
    font-family: 'roboto', sans-serif;
    padding: 0.25rem 1rem;
    border: 1.5px solid black;
    display: inline-flex;
    flex-direction: row;
    gap: 0.5rem;

    >.hide{
        display: none;
    }

    &:hover{
        cursor: pointer;
        background: black;
        color: white;
        
        >.button-icon{
            -webkit-filter: invert(100%); /* Safari/Chrome */
            filter: invert(100%);
        }
    }
}