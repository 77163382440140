@import url('https://fonts.googleapis.com/css2?family=Helvetica+Neue:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: 'Helvetica Neue', sans-serif;
}

.endslide6-container {
    position: relative;
    width: 100%;
    height: 100vh;
    background: url('../static/endslide5.png') no-repeat center center;
    background-size: cover;
}

.endslide6-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
        circle,
        #000000 0%,
        #000000 100%
    );
    opacity: 0.7;
}

.endslide6-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-align: center;
    color: white;
}

.endslide6-header1 {
    font-size: 64px;
    margin-bottom: 40px;
}

.highlight {
    background-color: #7B2D90;
    padding: 0 5px;
    text-decoration: underline;
    display: inline-flex;
    align-items: center;

    &:hover{
        cursor: pointer;
    }
}

.arrow-icon {
    margin-left: 10px;
    width: 40px;
    height: 40px;
}

.endslide6-subheader {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 40px;
    text-align: left;
}

.class-boxes {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.class-box {
    background: rgba(123, 45, 144, 0.7);
    border: 2px solid white;
    border-radius: 8px;
    padding: 20px;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.class-title {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: left;
}

.class-description {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    text-align: left;
}
