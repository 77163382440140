@import url('https://fonts.googleapis.com/css2?family=Helvetica+Neue:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: 'Helvetica Neue', sans-serif;
}

.slide1-container {
    position: relative;
    width: 100%;
    height: 100vh;
    background: url(../static/intro-splashScreen.png) no-repeat center center;
    background-size: cover;
}

.slide1-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
        circle,
        #9722B7 0%,
        #9722B7 70%,
        #240D2A 100%
    );
    opacity: 0.7;
}

.slide1-content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
    text-align: left;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.subheader {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 48px;
    z-index: 1;
}

.header1-box {
    display: flex;
    align-items: center;
    margin-bottom: 27px;
}

.header1 {
    font-size: 64px;
    margin: 0;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.man-icon {
    margin-left: 15px;
    width: 129px;
    height: 97px;
}

.description {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.description-item {
    display: flex;
    align-items: center;
}

.number-box {
    width: 35px;
    height: 35px;
    background: white;
    color: #7B2D90;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    border-radius: 4px;
}

.body-text {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    margin: 0;
}


.dots {
    position: absolute;
    bottom: 125px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    width: 100%;
}

.dot {
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    margin: 0 10px;
}

.dot.active {
    background-color: #BB6BD9;
}