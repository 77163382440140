.main-choice-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);

    .main-choices{

        display: flex;
        flex-direction: column;
        gap: 5rem;

        >.main-choice-icon{
            -khtml-user-select: none;
            -o-user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
            user-select: none;
            width: 90%;
            max-width: 1008px;
        }
    
        >.choices-box{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 2rem;
            font-size: 4rem;
            font-weight: 700;
            text-align: center;
    
            >.choices-header{
                color: white;
                font-family: "Helvetica Neue";
                font-size: 3rem;
                font-style: normal;
                font-weight: 700;
                width: 100%;
                text-align: left;
            }

            >.choice-buttons-container{
                display: flex;
                flex-direction: row;
                gap: 3rem;
                width: 90%;
                padding: 0rem 2rem;
                justify-content: center;
                align-items: center;

                >.choice-button{
                    font-family: "Roboto", sans-serif;
                    font-size: 1.5rem;
                    color: #7B2D90;
                    background: white;
                    border-radius: 0.625rem;
                    height: 6.25rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex: 0 0 50%;

                    &:hover{
                        cursor: pointer;
                        color: white;
                        background: #7B2D90;
                    }
                }
            }
        }
    }
}