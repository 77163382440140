@import url('https://fonts.googleapis.com/css2?family=Helvetica+Neue:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: 'Helvetica Neue', sans-serif;
}

.endslide3-back-arrow {
    position: absolute;
    top: 84px;
    left: 160px;
    width: 60px;
    height: 40px;
    cursor: pointer;
}

.endslide3-container {
    position: relative;
    width: 100%;
    height: 100vh;
    background: url('../static/endslide1.png') no-repeat center center; /* Update this path */
    background-size: cover;
}

.endslide3-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
        circle,
        #000000 0%,
        
        #000000 100%
    );
    opacity: 0.7;
}

.endslide3-content-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.4); /* White opacity background */
    border: 2px solid white; /* White border */
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 80px;
    padding-right: 80px;
    border-radius: 8px;
    width: 600px; /* Adjust as needed */
    height: 600;
    text-align: left;
}

.endslide3-content {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.endslide3-back-arrow {
    position: absolute;
    top: 84px;
    left: 140px;
    width: 60px;
    height: 40px;
    cursor: pointer;
}

.endslide3-subheader {
    font-size: 24px;
    font-weight: bold;
    z-index: 1; 
}

.endslide3-header1-box {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.header1 {
    font-size: 64px;
    margin: 0;
}

.endslide3-body-text {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    margin: 0;
    color: #FFFFFF;
}

.highlight {
    background-color: #7B2D90;
    padding: 0 5px;
    text-decoration: underline;
}


.dots {
    position: absolute;
    bottom: 125px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    width: 100%;
}

.dot {
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    margin: 0 10px;
}

.dot.active {
    background-color: #BB6BD9;
}

.slide-number {
    font-size: 24px; /* Subheader font size */
    margin-bottom: 20px;
    color: white;
}

.header1 {
    font-size: 64px; /* Header1 font size */
    margin-bottom: 20px;
    color: white;
}

.endslide3-body-text {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    margin: 10px 0;
    text-align: center;
    color: white;
    text-align: left;
}

.dots {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    width: 100%;
}

.dot {
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    margin: 0 10px;
}

.dot.active {
    background-color: #BB6BD9;
}

.begin-experience {
    position: absolute;
    bottom: 113px;
    right: 150px;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border: 2px solid white;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 18px;
}

.arrow-icon {
    margin-left: 10px;
    width: 16px;
    height: 16px;
}